/* align items */
.radioButton3 {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .radioButton3 .MuiRadio-root {
    margin-right: 3px;
  }
  
  /* radio button hover color */
  .radioButton3 .MuiRadio-root:hover {
    background-color: rgb(251,167,143, 0.5)  !important;
  }
  
  /* radio button color and font size */
  .radioButton3 .MuiSvgIcon-root {
    fill:  #39d9c4;
    font-size: 1rem;
  }
  
  /* ripple effect color */
  .radioButton3 .MuiRadio-colorSecondary.Mui-checked {
    color: rgb(251,167,143, 0.5);
  }
  